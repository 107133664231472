import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface NewsletterSignupConfirmationPageResponse {
    message: string;
}
interface NewsletterResultData {}

export const newsletterApi = createApi({
    reducerPath: 'newsletterApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/',
        prepareHeaders: headers => {
            headers.set(
                'litium-request-context',
                JSON.stringify(window.__litium.requestContext)
            );
            headers.set('Content-Type', 'application/json');
            return headers;
        },
        credentials: 'include',
    }),
    endpoints: builder => ({
        newsletterSignup: builder.mutation<
            NewsletterResultData,
            { form: HTMLFormElement }
        >({
            query: ({ form }) => {
                const formData = new FormData(form);
                const removeUniquePrefixForFieldNames = Array.from(
                    formData.entries()
                ).map(([key, value]) => {
                    const newName = key.split('-')[1];
                    return [newName, value];
                });

                return {
                    method: 'POST',
                    url: 'newslettersignup',
                    body: JSON.stringify(
                        Object.fromEntries(removeUniquePrefixForFieldNames)
                    ),
                    formData: true,
                };
            },
        }),
        newsletterConfirmSignup: builder.mutation<
            NewsletterSignupConfirmationPageResponse,
            { salt: string }
        >({
            query: ({ salt }) => ({
                method: 'POST',
                url: 'newslettersignupconfirmation',
                body: JSON.stringify(salt),
            }),
        }),
    }),
});

export const {
    useNewsletterSignupMutation,
    useNewsletterConfirmSignupMutation,
} = newsletterApi;

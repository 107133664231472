import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface LoginResultData {
    redirectUrl?: string;
    viewModelAsJson?: string;
}

interface ForgotPasswordResultData {
    message: string;
    redirectUrl?: string;
}

export const loginApi = createApi({
    reducerPath: 'loginApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/login',
        prepareHeaders: headers => {
            headers.set(
                'litium-request-context',
                JSON.stringify(window.__litium.requestContext)
            );
            headers.set(
                'RequestVerificationToken',
                window.localStorage.getItem('requestVerificationToken')
            );
            headers.set('Content-Type', 'application/json');
            return headers;
        },
        credentials: 'include',
    }),
    endpoints: builder => ({
        login: builder.mutation<LoginResultData, { form: HTMLFormElement }>({
            query: ({ form }) => ({
                method: 'POST',
                url: '/login',
                body: JSON.stringify(Object.fromEntries(new FormData(form))),
                formData: true,
            }),
        }),
        changePassword: builder.mutation<
            ForgotPasswordResultData,
            { form: HTMLFormElement }
        >({
            query: ({ form }) => ({
                method: 'POST',
                url: '/changepassword',
                body: JSON.stringify(Object.fromEntries(new FormData(form))),
                formData: true,
            }),
        }),
        forgotPassword: builder.mutation<
            ForgotPasswordResultData,
            { form: HTMLFormElement }
        >({
            query: ({ form }) => ({
                method: 'POST',
                url: `/forgotpassword`,
                body: JSON.stringify(Object.fromEntries(new FormData(form))),
                formData: true,
            }),
        }),
    }),
});

export const {
    useLoginMutation,
    useForgotPasswordMutation,
    useChangePasswordMutation,
} = loginApi;
